<template>
  <section class="report-wrapper">
    <b-card no-body>
      <b-card-body class="report_header pb-0">
        <div class="company">
          <h3 class="mb-1 text-left colorBlack">
            {{ userData.company_name_cn }}
          </h3>
        </div>
        <div class="title d-flex justify-content-between">
          <h5 class="mb-0 colorBlack">貸款詳細報表</h5>
          <label>列印時間：{{ getDateTime() }}</label>
        </div>
        <div class="info my-50">
          <b-row>
            <b-col cols="10">
              <b-row>
                <b-col cols="6">
                  <b-row>
                    <b-col cols="4"><b>放款日期：</b></b-col>
                    <b-col
                      cols="8"
                      v-if="
                        controlValue.last_pay_date_from ||
                        controlValue.last_pay_date_to
                      "
                    >
                      {{ controlValue.last_pay_date_from }} -
                      {{ controlValue.last_pay_date_to }}
                    </b-col>
                    <b-col cols="8" v-else> 不限 </b-col>
                  </b-row>
                </b-col>
                <b-col cols="6">
                  <b-row>
                    <b-col cols="4"><b>貸款類別：</b></b-col>
                    <b-col cols="8" v-if="controlValue.loan_type_id">{{
                      loanTypeOptions &&
                      loanTypeOptions.length > 0 &&
                      loanTypeOptions.find(
                        (ele) => ele.id == controlValue.loan_type_id
                      ).name
                    }}</b-col>
                    <b-col cols="8" v-else> 不限 </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="10">
              <b-row>
                <b-col cols="6">
                  <b-row>
                    <b-col cols="4"><b>現時狀態:</b></b-col>
                    <b-col
                      cols="8"
                    >
                      {{ controlValue.status }} 
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
      <table class="w-100">
        <thead>
          <tr>
            <td>
              <div class="header-space">&nbsp;</div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b-card-body class="report_content">
                <table class="report-table">
                  <thead>
                    <tr>
                      <th>放款日期</th>
                      <th>貸款編號</th>
                      <th>客戶名稱</th>
                      <th>貸款類別</th>
                      <th>總期數</th>
                      <th>年利率</th>
                      <th>支付方式</th>
                      <th>現時狀態</th>
                      <th>應還本金</th>
                      <th>已還本金</th>
                      <th>本金結欠</th>
                      <th>全期利息</th>
                      <th>已還利息</th>
                      <th>尚欠利息</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item of reportList" :key="item.id">
                      <th>{{ item.loan_date }}</th>
                      <th>{{ item.contract_number }}</th>
                      <th>{{ item.name_cn }}</th>
                      <th>{{ item.loan_type.name }}</th>
                      <th>{{ item.period }}期</th>
                      <th>{{ item.annual_interest_rate }}</th>
                      <th>{{ item.payment_method.name }}</th>
                      <th>{{ item.status }}</th>
                      <th>${{ item.total.total_principal }}</th>
                      <th>${{ item.total.total_paid_principal }}</th>
                      <th>${{ item.total.total_principal - item.total.total_paid_principal }}</th>
                      <th>{{ item.total.total_interest }}</th>
                      <th>{{ item.total.total_paid_interest }}</th>
                      <th>{{ item.total.total_interest - item.total.total_paid_interest }}</th>
                    </tr>
                  </tbody>
                </table>
              </b-card-body>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div class="footer-space">&nbsp;</div>
            </td>
          </tr>
        </tfoot>
      </table>
      <b-card-body class="report_footer py-0">
        <table class="report-table">
          <tfoot>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>
                ${{
                  reportList.reduce(
                    (acc, curr) => acc + Number(curr.total.total_principal),
                    0
                  )
                }}
              </th>
              <th>
                ${{
                  reportList.reduce(
                    (acc, curr) => acc + Number(curr.total.total_paid_principal),
                    0
                  )
                }}
              </th>
              <th>
                ${{
                  reportList.reduce(
                    (acc, curr) => acc + (Number(curr.total.total_principal) - Number(curr.total.total_paid_principal)),
                    0
                  )
                }}
              </th>
              <th>
                ${{
                  reportList.reduce(
                    (acc, curr) => acc + Number(curr.total.total_interest),
                    0
                  )
                }}
              </th>
              <th>
                ${{
                  reportList.reduce(
                    (acc, curr) => acc + Number(curr.total.total_paid_interest),
                    0
                  )
                }}
              </th>
              <th>
                ${{
                  reportList.reduce(
                    (acc, curr) =>
                      acc +
                      (Number(curr.total.total_interest) - Number(curr.total.total_paid_interest)),
                    0
                  )
                }}
              </th>
            </tr>
          </tfoot>
        </table>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardBody, BCardText } from "bootstrap-vue";

import { mapFields } from "vuex-map-fields";

export default {
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      month: [
        "Jan",
        "Feb",
        "March",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    };
  },
  methods: {},
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
  },
  props: {
    reportList: {
      type: Array,
      required: true,
    },
    loanTypeOptions: {
      type: Array,
      required: true,
    },
  },

  mounted() {
    // this.$nextTick(() => {
    //     setTimeout(() => {
    //         this.$emit("domRendered");
    //     }, 1000);
    // });
  },
  computed: {
    ...mapFields(["controlValue"]),
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
.report-table {
  font-size: 12px;
  th {
    width: 50px;
    font-size: 11px;
  }
}

.report_content {
  margin-top: 90px;
  .report-table {
  }
}
</style>